/* Common Imports */
import React from "react";
import { withRouter } from "next/router";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import { Button, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  link: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "&:hover": {
      textDecoration: "none",
    },
  },
  navButtonActive: {
    color: "#2784C8",
    // borderBottomStyle: "solid",
    borderBottomWidth: "2px",
    borderImage: "#2784C8",
    // padding: "1.2rem 1rem 1rem 0rem",
    padding: "0rem 1rem 0rem 0rem",
    borderRadius: "50%",
    fontWeight: "700",
    fontSize: "0.875rem",
    lineHeight: "1.3125rem",
    textTransform: "capitalize",
    position: "relative",
    zIndex: 1,
    "& :after": {
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "20%",
      content: '""',
      borderBottomStyle: "solid",
      borderRadius: "50%",
      borderBottomWidth: "2px",
      borderImage: "#2784C8",
      marginLeft: "40%",
    },
  },
  navButton: {
    color: "#18191F",
    borderBottom: "solid 2px #FFFFFF",
    // padding: "1.5rem 1rem 1.3rem 1rem",
    padding: "0rem 1rem 0rem 0rem",
    borderRadius: 0,
    fontWeight: "700",
    fontSize: "0.875rem",
    lineHeight: "1.3125rem",
    textTransform: "capitalize",
  },
  hideThis: {
    display: "none",
  },
  marginLeft: {
    marginLeft: "1rem",
  },
}));

// const DispenseIcon = (iconString) => {
//   switch (iconString) {
//     case "navbar/home/active":
//       return "/images/icons/nav/home-red.svg";
//     default:
//       return "/images/icons/nav/home-red.svg";
//   }
// };

const NavButton = (props) => {
  const classes = useStyles();
  // const navIconClass =
  //   props.router.pathname == props.href ? "" : classes.hideThis;
  return (
    <React.Fragment>
      <Link
        href={props.href}
        rel="noopener"
        aria-label={props.text}
        title={props.text}
        className={classes.link}
      >
        <Button
          aria-controls={props.ariaControls}
          aria-haspopup={props.ariaHaspopup}
          onClick={props.onClick}
          className={
            props.router.pathname == props.href
              ? classes.navButtonActive
              : classes.navButton
          }
        >
          {/* <img
            decode="async"
            alt="/images/illustrations/common/ImageNotFound.svg"
            longdesc={props.href ? props.href : "#"}
            loading="lazy"
            referrerPolicy="no-referrer"
            height={26}ListItemIcon,
            width={26}
            className={navIconClass}
            style={{ marginRight: "0.25rem", marginLeft: "0.7rem" }}
            src={DispenseIcon(props.icon)}
          /> */}
          <span className={classes.marginLeft}>{props.text}</span>
        </Button>
      </Link>
    </React.Fragment>
  );
};

export default withRouter(NavButton);
