/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

/* Component Imports */

import {
  AppBar,
  Toolbar,
  Link,
  useScrollTrigger,
  Button,
} from "@material-ui/core";
import NavButton from "./navbar-components/NavButton";
// import NavDropdownMenuServices from "./navbar-components/NavDropdownMenuServices";
// import NavDropdownMenuCompany from "./navbar-components/NavDropdownMenuCompany";
import SideNav from "./navbar-components/SideNav";

/* Data Imports */

import AppConfig from "../../AppConfig";

const useStyles = makeStyles((theme) => ({
  /* Navbar New */
  appBar: {
    backgroundColor: "#FFFFFF",
    height: "4rem",
    padding: "0rem 1rem 0rem 1rem",
  },
  toolBar: {
    width: "100%",
    height: "4rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // [theme.breakpoints.down("xs")]: {
    //   justifyContent: "center",
    //   padding: 0,
    // },
    margin: "0rem 0rem 0rem 0rem",
  },
  logoLink: {
    width: 80,
    height: 22,
  },
  callBtn: {
    height: "40px",
    width: "80px",
    background: "linear-gradient(90deg, #6C4CEF 0%, #C74CC1 100%)",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    color: "#ffffff",
    padding: "0.625rem 0.938rem",
    marginLeft: "2rem",
    textTransform: "capitalize",
    "&:hover": {
      background: "linear-gradient(90deg, #CC3A30 0%, #C31D54 100%)",
    },
    "&:disabled": {
      background: "#9E9E9E",
      color: "#CFD8DC",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  menuButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#808080",
    padding: "0rem 0rem 0rem 0rem",
    width: "1.5rem",
    height: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  mobileLogoContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginLeft: "-4rem",
    },
  },
  logoImg: {
    height: "1.5rem",
    width: "1.5rem",
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Navbar = (props) => {
  const classes = useStyles();
  const [drawerSwitch, setDrawerSwitch] = React.useState(false);
  return (
    <React.Fragment>
      {/* SideNav Phone */}
      <SideNav
        open={drawerSwitch}
        onClose={() => {
          setDrawerSwitch(false);
        }}
      />

      {/* Navbar Desktop */}
      <ElevationScroll {...props}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Button
              color="inherit"
              aria-label="Open Drawer"
              edge="start"
              onClick={() => {
                setDrawerSwitch(!drawerSwitch);
              }}
              className={classes.menuButton}
            >
              <img
                decode="async"
                alt={AppConfig.imageAltText}
                longdesc={props.href ? props.href : "#"}
                loading="lazy"
                referrerPolicy="no-referrer"
                height={24}
                width={24}
                src="/images/icons/misc/menu.svg"
                className={classes.logoImg}
              />
            </Button>

            {/* Logo */}
            <div className={classes.mobileLogoContainer}>
              <Link
                href="/"
                rel="noopener"
                aria-label="Beegru"
                title="Home"
                className={classes.logoLink}
              >
                <img
                  loading="lazy"
                  decode="async"
                  alt={AppConfig.imageAltText}
                  longdesc="https://snapanalytx.motherlod.com"
                  referrerPolicy="no-referrer"
                  width={128}
                  height={26}
                  style={{ marginRight: "0.25rem" }}
                  src="/images/logo/logo.svg"
                />
              </Link>
            </div>
            {/* Page Links */}
            <div>
              <NavButton href="/" text="Home" icon="navbar/home/active" />
              <NavButton
                href="/#about-us"
                text="About Us"
                icon="navbar/home/active"
              />
              <NavButton
                href="/reports"
                text="Reports"
                icon="navbar/home/active"
              />
              <NavButton
                href="/classes"
                text="Classes"
                icon="navbar/home/active"
              />
              <NavButton
                href="#contact-us"
                text="Contact Us"
                icon="navbar/home/active"
              />
              {/* <NavDropdownMenuServices href="/services" text="Services" />
              <NavDropdownMenuCompany href="/company" text="Company" />
              <Button
                className={classes.callBtn}
                href={"tel:" + AppConfig.phoneNumber}
                title="Call Us"
              >
                Call Us
              </Button> */}
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar className={classes.toolBar} />
    </React.Fragment>
  );
};

export default Navbar;
