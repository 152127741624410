const MODE = process.env.mode;

var tempAc = {};

MODE == "development"
  ? (tempAc = {
    urlPrefix: process.env.baseUrlDevelopment,
    apiUrlPrefix: process.env.baseUrlDevelopment + "api/",
    classUrlPrefix: process.env.baseUrlDevelopment + "classes/",
    reportUrlPrefix: process.env.baseUrlDevelopment + "reports/",
    imageAltText: "Unable to load image",
    imageLongDesc: "https://snapanalytx.motherlod.com",
    phoneNumber: "18005720999",
    email: "mail@beegru.com",
  })
  : (tempAc = {
    urlPrefix: process.env.baseUrlProduction,
    apiUrlPrefix: process.env.baseUrlProduction + "api/",
    classUrlPrefix: process.env.baseUrlProduction + "classes/",
    reportUrlPrefix: process.env.baseUrlProduction + "reports/",
    imageAltText: "Unable to load image",
    imageLongDesc: "https://snapanalytx.motherlod.com",
    phoneNumber: "18005720999",
    email: "mail@beegru.com",
  });
const AppConfig = tempAc;

export default AppConfig;
