/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import BeegruContainer from "./BeegruContainer";
import { Typography, Link } from "@material-ui/core";

/* Data Imports */

import AppConfig from "../../AppConfig";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    padding: "1rem 0rem 0rem 0rem",
    backgroundColor: "#0B0D17",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "space-between",
    },
  },
  copyrightDesktop: {
    color: "#FFFFFF",
    margin: "0.25rem",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  copyrightMobile: {
    color: "#FFFFFF",
    margin: "0.25rem",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  socialLinks: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0.25rem",
  },
  socialLinksIcon: {
    marginRight: "0.25rem",
    background: "#474A57",
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
  },
  socialLinksIconLink: {
    margin: "0rem 0.5rem 0rem 0.5rem",
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.footerContainer}>
        <BeegruContainer>
          <div className={classes.footer}>
            <img
              loading="lazy"
              decode="async"
              alt={AppConfig.imageAltText}
              longdesc="https://snapanalytx.motherlod.com"
              referrerPolicy="no-referrer"
              width={128}
              height={26}
              style={{ margin: "0.25rem" }}
              src="/images/logo/logo.svg"
            />
            <Typography
              variant="body2"
              component="span"
              className={classes.copyrightDesktop}
            >
              &copy; {new Date().getFullYear()} Snapanalytx Inc. All rights
              reserved.
            </Typography>
            <div className={classes.socialLinks}>
              <Link
                href="https://facebook.com"
                rel="noopener"
                title="Follow us on Facebook!"
                className={classes.socialLinksIconLink}
              >
                <img
                  loading="lazy"
                  decode="async"
                  alt={AppConfig.imageAltText}
                  longdesc="https://snapanalytx.motherlod.com"
                  referrerPolicy="no-referrer"
                  width={32}
                  height={32}
                  className={classes.socialLinksIcon}
                  src="/images/icons/social/facebook-white.svg"
                />
              </Link>
              <Link
                href="https://linkedin.com"
                rel="noopener"
                title="Follow us on LinkedIn!"
                className={classes.socialLinksIconLink}
              >
                <img
                  loading="lazy"
                  decode="async"
                  alt={AppConfig.imageAltText}
                  longdesc="https://snapanalytx.motherlod.com"
                  referrerPolicy="no-referrer"
                  width={32}
                  height={32}
                  className={classes.socialLinksIcon}
                  src="/images/icons/social/linkedin-white.svg"
                />
              </Link>
              <Link
                href="https://instagram.com"
                rel="noopener"
                title="Follow us on Instagram!"
                className={classes.socialLinksIconLink}
              >
                <img
                  loading="lazy"
                  decode="async"
                  alt={AppConfig.imageAltText}
                  longdesc="https://snapanalytx.motherlod.com"
                  referrerPolicy="no-referrer"
                  width={32}
                  height={32}
                  className={classes.socialLinksIcon}
                  src="/images/icons/social/instagram-white.svg"
                />
              </Link>
              <Link
                href="https://twitter.com"
                rel="noopener"
                title="Follow us on Twitter!"
                className={classes.socialLinksIconLink}
              >
                <img
                  loading="lazy"
                  decode="async"
                  alt={AppConfig.imageAltText}
                  longdesc="https://snapanalytx.motherlod.com"
                  referrerPolicy="no-referrer"
                  width={32}
                  height={32}
                  className={classes.socialLinksIcon}
                  src="/images/icons/social/twitter-white.svg"
                />
              </Link>
            </div>
            <Typography
              variant="body2"
              component="span"
              className={classes.copyrightMobile}
            >
              &copy; {new Date().getFullYear()} Snapanalytx, Inc. All rights
              reserved.
            </Typography>
          </div>
        </BeegruContainer>
      </div>
    </React.Fragment>
  );
};

export default Footer;
