/* React Imports */

import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "next/router";

/* Component Imports */

import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import BeegruContainer from "./BeegruContainer";

/* Data Imports */
import AppConfig from "../../AppConfig";

const Layout = (props) => {
  return (
    <React.Fragment>
      <div id="rootDiv">
        <Header
          keywords={
            props.keywords
              ? props.keywords
              : "snap, analytx, analytics, cloud, azure, microsoft, google, aws, amazon, web, services, digital, ocean, alibaba, yotta, github, reports"
          }
          description={
            props.description
              ? props.description
              : "Snapanalytx is a cloud strategy advisory"
          }
          title={props.title ? props.title : "Snapanalytx"}
          canonicalUrl={
            props.canonicalUrl
              ? props.canonicalUrl
              : AppConfig.urlPrefix + props.router.asPath.substring(1)
          }
        />
        <Navbar />
        <Helmet>
          <html lang="en-GB" />
        </Helmet>
        <BeegruContainer>{props.children}</BeegruContainer>
        <Footer id="footer" />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Layout);
